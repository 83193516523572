import * as Sentry from '@sentry/vue';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.config.errorHandler = (error, instance, info) => {
    console.log(error, instance, info);

    showError({
      message: 'Coś poszło nie tak',
    });

    Sentry.captureException(error);
  };

  nuxtApp.hook('vue:error', (error, instance, info) => {
    console.error(error, instance, info);

    showError({
      message: 'Coś poszło nie tak',
    });

    Sentry.captureException(error);
  });
});
