import { z } from 'zod';

export const userSchema = z.object({
  token: z.string(),
  name: z.string(),
  redirectTo: z.string().nullable().optional(),
});

export const courseMaterial = z.object({
  id: z.number(),
  title: z.string(),
  file_url: z.string(),
});

export const progressSchema = z.object({
  progress_sec: z.number(),
  progress_percent: z.number(),
  watched_sum: z.number(),
  duration: z.number(),
  is_complete: z.boolean(),
  updated_at: z.string(),
});

export const topicSchema = z.object({
  id: z.number(),
  title: z.string(),
  slug: z.string(),
  description: z.string().nullable(),
  video_player: z.string().nullable(),
  progress: progressSchema.nullable(),
  player_type: z.enum(['yt', 'vimeo']),
});

export const lessonSchema = z.object({
  id: z.number(),
  title: z.string(),
  slug: z.string(),
  description: z.string().nullable(),
  video_player: z.string().nullable(),
  topics: z.array(topicSchema).nullable(),
  is_complete: z.boolean(),
  tips: z.string().nullable(),
  player_type: z.enum(['yt', 'vimeo']),
});

export const courseSchema = z.object({
  id: z.number(),
  title: z.string(),
  slug: z.string(),
  description: z.string().nullable(),
  lessons: z.array(lessonSchema).nullable(),
  thumbnail_url: z.string().nullable(),
  materials: z.array(courseMaterial),
  expiration_date: z.string().nullable(),
});

export const coursesSchema = z.array(courseSchema);

export const userAccountSchema = z.object({
  address_url: z.string().nullable(),
  email: z.string().email(),
  name: z.string(),
  phone: z.string().nullable(),
  slug: z.string().nullable(),
  status: z.enum(['active', 'inactive', 'pending']).nullable(),
  is_schedule_onboarding: z.number().nullable(),
  is_episode_onboarding: z.number().nullable(),
  terms_accepted: z.number().nullable(),
  has_accepted_regulations: z.number().nullable(),
  is_dark_theme: z.number().nullable(),
  created_at: z.string().nullable(),
  updated_at: z.string().nullable(),
  zmidAssoc: z
    .object({
      subscribction_id: z.number().nullable(),
      is_active: z.number().nullable(),
      end_date: z.string().nullable(),
    })
    .nullable(),
  examino: z
    .object({
      start_date: z.string().nullable(),
      end_date: z.string().nullable(),
      subscribction_id: z.number().nullable(),
      code: z.string().nullable(),
      is_active: z.number().nullable(),
      created_at: z.string().nullable(),
      updated_at: z.string().nullable(),
    })
    .nullable(),
  signed_for_course: z.string().nullable(),
  student: z.number().nullable(),
  payment_awaiting: z.boolean().nullable(),
  wp_examino: z.boolean().nullable(),
  wp_stationary: z.boolean().nullable(),
  wp_vod: z.boolean().nullable(),
  wp_date_exam: z.string().nullable(),
});

export const loginFormSchema = z.object({
  email: z
    .string({ message: 'E-mail jest wymagany' })
    .email({ message: 'Błędny adres e-mail' }),
  password: z.string({ message: 'Hasło jest wymagane' }),
  rememberMe: z.boolean().optional(),
});

export const registerFormSchema = z
  .object({
    email: z
      .string({ message: 'E-mail jest wymagany' })
      .email({ message: 'Błędny adres e-mail' }),
    password: z
      .string({ message: 'Hasło jest wymagane' })
      .min(8, { message: 'Hasło powinno mieć conajmniej 8 znaków' }),
    confirm_password: z.string({
      message: 'Potwierdzenie hasła jest wymagane',
    }),
    fist_name: z.string({ message: 'Imię jest wymagane' }),
    last_name: z.string({ message: 'Nazwisko jest wymagane' }),
    consent1: z
      .boolean({
        message: 'Zgoda jest wymagana',
      })
      .refine((val) => val === true, {
        message: 'Zgoda jest wymagana',
      }),
    consent2: z
      .boolean({
        message: 'Zgoda jest wymagana',
      })
      .refine((val) => val === true, {
        message: 'Zgoda jest wymagana',
      }),
  })
  .refine((data) => data.password === data.confirm_password, {
    message: 'Hasła różnią się od siebie',
    path: ['confirm_password'],
  });

export const contactFormSchema = z.object({
  phone: z
    .string()
    .optional()
    .refine((val) => !val || /^\d{9}$/.test(val), {
      message: 'Niepoprawny numer telefonu',
    }),
  topic: z.string({ message: 'Temat wiadomości jest wymagany' }),
  message: z.string({ message: 'Treść wiadomości jest wymagana' }),
});

export const scheduleSchema = z.object({
  id: z.number(),
  event_date: z.string(),
  description: z.string().nullable(),
  type: z.enum([
    'meeting',
    'exam',
    'event',
    'internal_exam',
    'transmission',
    'other',
    'organization_meeting',
  ]),
  url: z.string().nullable(),
  created_at: z.string().nullable(),
  google_calendar_url: z.string().nullable(),
  new_tab: z.boolean().nullable().optional(),
});

export const scheludesSchema = z.array(scheduleSchema);

export const newsSchema = z.object({
  id: z.number(),
  title: z.string(),
  description: z.string().nullable(),
  type: z.enum(['new_course', 'discount', 'other', 'event', 'exam_news']),
  url: z.string().nullable(),
  created_at: z.string().nullable(),
  seen: z.boolean(),
});

export const newsArraySchema = z.array(newsSchema);

export const jobOfferSchema = z.object({
  id: z.number(),
  title: z.string(),
  description: z.string(),
  location: z.string().nullable(),
  employer: z.string().nullable(),
  url: z.string(),
  created_at: z.string().nullable(),
  seen: z.boolean(),
});

export const jobOffersSchema = z.array(jobOfferSchema);

export const informationSchema = z.object({
  id: z.number(),
  title: z.string(),
  description: z.string().nullable(),
  type: z.string().nullable(),
  url: z.string().nullable(),
  created_at: z.string().nullable(),
  seen: z.boolean(),
});

export const informationsSchema = z.array(informationSchema);

export const notificationSchema = z.object({
  id: z.number(),
  title: z.string(),
  description: z.string().nullable(),
  url: z.string().nullable(),
  created_at: z.string().nullable(),
  is_unread: z.union([z.literal(0), z.literal(1), z.null()]),
  new_tab: z.boolean(),
});

export const notificationsSchema = z.array(notificationSchema);

export const keepWatchingSchema = z.object({
  topic_id: z.number(),
  course_id: z.number().nullable(),
  lesson_id: z.number(),
  duration: z.number().nullable(),
  progress_percent: z.number().nullable(),
  progress_sec: z.number().nullable(),
  updated_at: z.string().nullable(),
});

export const keepWatchingsSchema = z.array(keepWatchingSchema);

export const materialSchema = z.object({
  id: z.number(),
  title: z.string(),
  courses: z.array(
    z.object({
      id: z.number(),
    }),
  ),
  type: z.enum([
    'presentation',
    'task',
    'table',
    'exam',
    'other',
    'patterns_card',
    'law_script',
    'law_act',
    'ordinance',
    'legal_act',
    'knf_exam',
  ]),
  updated_at: z.string().nullable(),
  file_url: z.string(),
  is_free: z.boolean(),
});

export const materialsSchema = z.array(materialSchema);

export const addressFormBaseSchema = z.object({
  first_name: z
    .string({
      message: 'Imię jest wymagane',
    })
    .min(1, 'Imię jest wymagane'),
  last_name: z
    .string({
      message: 'Nazwisko jest wymagane',
    })
    .min(1, 'Nazwisko jest wymagane'),
  line1: z
    .string({
      message: 'Adres jest wymagany',
    })
    .min(1, 'Adres jest wymagany'),
  city: z
    .string({
      message: 'Miasto jest wymagane',
    })
    .min(1, 'Miasto jest wymagane'),
  postal_code: z
    .string({
      message: 'Kod pocztowy jest wymagany',
    })
    .min(1, 'Kod pocztowy jest wymagany')
    .regex(/(^$)|(^\d{2}-\d{3}$)/, 'Niepoprawny kod pocztowy'),
  phone: z
    .string()
    .optional()
    .refine((val) => !val || /^\d{3}\s?\d{3}\s?\d{3}$/.test(val), {
      message: 'Niepoprawny numer telefonu',
    }),
});

export const addressFormPersonalSchema = addressFormBaseSchema.extend({
  is_company: z.literal(false),
  company_name: z.string().optional(),
  tax_number: z.string().optional(),
});

export const addressFormCompanySchema = addressFormBaseSchema.extend({
  is_company: z.literal(true),
  company_name: z
    .string({
      message: 'Nazwa firmy jest wymagana',
    })
    .min(1, 'Nazwa firmy jest wymagana'),
  tax_number: z
    .string({ message: 'NIP jest wymagany' })
    .refine((val) => !val || /^\d{10}$/.test(val), {
      message: 'Niepoprawny NIP',
    }),
});

export const addressFormSchema = z.union([
  addressFormCompanySchema,
  addressFormPersonalSchema,
]);

export const userDataFormSchema = z.object({
  email: z
    .string({ message: 'E-mail jest wymagany' })
    .email({ message: 'Błędny adres e-mail' }),
  phone: z
    .string()
    .optional()
    .refine((val) => !val || /^\d{9}$/.test(val), {
      message: 'Niepoprawny numer telefonu',
    }),
  terms_accepted: z.boolean().optional(),
});

export const addressSchema = z.object({
  line1: z.string().nullable(),
  city: z.string().nullable(),
  company_name: z.string().nullable(),
  first_name: z.string().nullable(),
  last_name: z.string().nullable(),
  tax_number: z.string().nullable(),
  postal_code: z.string().nullable(),
  country_id: z.number().nullable(),
  is_company: z.boolean().nullable(),
  phone: z
    .string()
    .optional()
    .refine((val) => !val || /^\d{3}\s?\d{3}\s?\d{3}$/.test(val), {
      message: 'Niepoprawny numer telefonu',
    })
    .nullable(),
});

export const searchSchema = z.array(
  z.object({
    course_slug: z.string(),
    course_title: z.string(),
    lessons: z.array(
      z.object({
        title: z.string(),
        slug: z.string(),
      }),
    ),
  }),
);

export const changePasswordFormSchema = z
  .object({
    current_password: z.string({ message: 'Aktualne hasło jest wymagane' }),
    new_password: z
      .string({ message: 'Nowe hasło jest wymagane' })
      .min(8, { message: 'Hasło powinno mieć conajmniej 8 znaków' }),
    new_password_confirmation: z.string({
      message: 'Potwierdzenie hasła jest wymagane',
    }),
  })
  .refine((data) => data.new_password === data.new_password_confirmation, {
    message: 'Hasła różnią się od siebie',
    path: ['new_password_confirmation'],
  });

export const remindPasswordEmailFormSchema = z.object({
  email: z
    .string({ message: 'E-mail jest wymagany' })
    .email({ message: 'Błędny adres e-mail' }),
});

export const remindPasswordResetFormSchema = z
  .object({
    password: z
      .string({ message: 'Hasło jest wymagane' })
      .min(8, { message: 'Hasło powinno mieć conajmniej 8 znaków' }),
    password_confirmation: z.string({
      message: 'Potwierdzenie hasła jest wymagane',
    }),
    token: z.string(),
    email: z.string().email(),
  })
  .refine((data) => data.password === data.password_confirmation, {
    message: 'Hasła różnią się od siebie',
    path: ['password_confirmation'],
  });

export const emailSchema = z.string().email();

export const tokenSchema = z.string().regex(/^[A-Za-z0-9-_]{32,64}$/);

export const examSchema = z.object({
  courses: z.array(z.number()).nullable(),
  id: z.number(),
  title: z.string(),
  how_join: z.string().nullable(),
  information: z.string(),
  slug: z.string(),
  terms: z.string().nullable(),
  video_url: z.string().nullable(),
  link: z
    .array(
      z.object({
        url: z.string(),
      }),
    )
    .nullable(),
  dates: z
    .array(
      z.object({
        date: z.string(),
      }),
    )
    .nullable(),
});

export const examsSchema = z.array(examSchema);

export const certificateSchema = z.object({
  title: z.string(),
  description: z.string(),
  file_name: z.string(),
  created_at: z.string(),
});

export const certificatesSchema = z.array(certificateSchema);

export const eventsOptionsSchema = z.object({
  get_access: z.union([z.literal(0), z.literal(1)]).nullable(),
  get_examino_code: z.union([z.literal(0), z.literal(1)]).nullable(),
  get_finish_course: z.union([z.literal(0), z.literal(1)]).nullable(),
  get_certificate: z.union([z.literal(0), z.literal(1)]).nullable(),
  get_classes_today: z.union([z.literal(0), z.literal(1)]).nullable(),
  get_meeting_today: z.union([z.literal(0), z.literal(1)]).nullable(),
  get_zmid_assoc: z.union([z.literal(0), z.literal(1)]).nullable(),
  get_zmid_assoc_pay: z.union([z.literal(0), z.literal(1)]).nullable(),
  get_course_7_end: z.union([z.literal(0), z.literal(1)]).nullable(),
  get_zmid_assoc_delete: z.union([z.literal(0), z.literal(1)]).nullable(),
  get_course_end: z.union([z.literal(0), z.literal(1)]).nullable(),
});

export const priceSchema = z.object({
  currency: z.string(),
  product_id: z.number(),
  unit_amount_with_tax_formatted: z.string(),
  stripe_id: z.string(),
  is_default: z.boolean(),
  type: z.enum(['one_time', 'recurring']),
  id: z.number(),
  recurring_interval: z.string().nullable(),
  unit_amount_with_tax: z.number(),
});

export const productsSchema = z.array(
  z.object({
    id: z.number(),
    name: z.string(),
    prices: z.array(priceSchema),
    is_archived: z.boolean(),
  }),
);

export const subscriptionsSchema = z.array(
  z.object({
    current_period_end_at: z.string(),
    is_subscribed: z.boolean(),
    id: z.number(),
    ends_at: z.string().nullable(),
  }),
);

export const cardsSchema = z.array(
  z.object({
    last_four: z.string(),
    brand: z.string(),
    exp_month: z.number(),
    exp_year: z.number(),
    is_default: z.boolean(),
    id: z.number(),
  }),
);

export const paymentsHistorySchema = z.array(
  z.object({
    id: z.number(),
    paymentable: z.object({
      paid_at: z.string().optional(),
      current_period_start_at: z.string().optional(),
    }),
    amount_paid_with_tax_formatted: z.string(),
  }),
);

export const checkoutCourseBaseSchema = z.object({
  product_id: z.number(),
  title: z.string(),
  thumbnail_url: z.string().nullable(),
  slug: z.string(),
  acronym: z.string().nullable(),
  student_price_id: z.number().nullable(),
  show_examino_option: z.boolean(),
  show_stationary_option: z.boolean(),
  show_vod_option: z.boolean(),
  currency: z.enum(['PLN', 'CHF']),
  vod_option_label: z.string().nullable(),
  vod_option_price_id: z.number().nullable(),
  vod_option_product_id: z.number().nullable(),
  examino_option_label: z.string().nullable(),
});

export const checkoutExamsCoursesSchema = z.object({
  ...checkoutCourseBaseSchema.shape,
  current_course_price: z.object({
    course_price_exams: z.array(
      z.object({
        price_id: z.number(),
        exam_at: z.string(),
      }),
    ),
  }),
});

export const checkoutCoursesDefaultSchema = z.object({
  ...checkoutCourseBaseSchema.shape,
  regular_price_id: z.number(),
});

export const checkoutCourseSchema = z.union([
  checkoutCoursesDefaultSchema,
  checkoutExamsCoursesSchema,
]);

export const paymentMethodTypeSchema = z.enum(['card', 'transfer', 'p24'], {
  message: 'Wybierz metodę płatności',
});

export const checkoutPropsSchema = z.object({
  payment_method_type: paymentMethodTypeSchema,
  code: z.string().optional(),
  single_payment_items: z.array(
    z.object({
      price_id: z.number(),
      quantity: z.number(),
    }),
  ),
});

export const checkoutFormSchema = z.object({
  payment_method_type: paymentMethodTypeSchema,
  code: z.string().optional(),
  single_payment_items: z.array(
    z.object({
      price_id: z.number(),
      quantity: z.number(),
    }),
  ),
  comments: z.string().optional(),
  is_examino: z.boolean().optional(),
  is_student: z.boolean().nullable().optional(),
  is_vod: z.boolean().nullable().optional(),
  is_offline: z.boolean().nullable().optional(),
});

export const feeSchema = z.object({
  fee_gross: z.number(),
  fee_net: z.number(),
  sum_prices_gross_amount: z.number(),
  sum_prices_net_amount: z.number(),
});

export const membershipFormSchema = z.object({
  phone: z
    .string()
    .optional()
    .refine((val) => !val || /^\d{9}$/.test(val), {
      message: 'Niepoprawny numer telefonu',
    }),
  certificat_type: z.array(z.string()).optional(),
  is_newsletter: z.boolean().optional(),
  ethics_consent: z
    .boolean({
      message: 'Zgoda jest wymagana',
    })
    .refine((val) => val === true, {
      message: 'Zgoda jest wymagana',
    }),
  zmid_consent: z
    .boolean({
      message: 'Zgoda jest wymagana',
    })
    .refine((val) => val === true, {
      message: 'Zgoda jest wymagana',
    }),
  referral_source: z.string().optional(),
});

export const promotionCodePercentSchema = z.object({
  coupon_name: z.string(),
  coupon_percent_off: z.number(),
  coupon_is_valid: z.boolean(),
});

export const promotionCodeAmountSchema = z.object({
  coupon_name: z.string(),
  coupon_amount_off: z.number(),
  coupon_is_valid: z.boolean(),
  coupon_amount_off_with_tax: z.number(),
});

export const promotionCodeDataSchema = z.union([
  promotionCodePercentSchema,
  promotionCodeAmountSchema,
]);

export const searchTopicsSchema = z.array(
  z.object({
    course_slug: z.string(),
    course_title: z.string(),
    lessons: z.array(
      z.object({
        lesson_title: z.string(),
        lesson_slug: z.string(),
        topics: z.array(
          z.object({
            title: z.string(),
            slug: z.string(),
          }),
        ),
      }),
    ),
  }),
);

export const coursesListSchema = z.array(
  z.object({
    slug: z.string(),
    title: z.string(),
    expiration_date: z.string().nullable().optional(),
    id: z.number(),
    product_id: z.number().nullable(),
  }),
);

export const examino = z
  .object({
    start_date: z.string().nullable(),
    end_date: z.string().nullable(),
    subscribction_id: z.number().nullable(),
    code: z.string().nullable(),
    is_active: z.number().nullable(),
    created_at: z.string().nullable(),
    updated_at: z.string().nullable(),
  })
  .nullable();

export const regulationsSchema = z.array(
  z.object({
    id: z.number(),
    name: z.string(),
    slug: z.string(),
    content: z.string(),
    is_active: z.boolean().nullable(),
    created_at: z.string().nullable(),
    updated_at: z.string().nullable(),
  }),
);
