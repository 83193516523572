import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { useStorage } from '@vueuse/core';
import { userService } from '~/utils/api/userService';

export function useTheme() {
  const { data: user } = useUser();
  const isDarkTheme = useStorage('isDarkTheme', false);
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutate } = useMutation({
    mutationFn: () => userService.toggleTheme(),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['user'] });
    },
    onError: () => {
      toast.add({
        severity: 'error',
        detail: 'Wystąpił błąd podczas zmiany motywu',
        life: 3000,
      });
    },
  });

  function updateTheme() {
    isDarkTheme.value = !isDarkTheme.value;
    mutate();
  }

  watch(
    user,
    () => {
      if (user.value) {
        isDarkTheme.value = !!user.value.is_dark_theme;
      }
    },
    {
      immediate: true,
    },
  );

  watch(
    isDarkTheme,
    () => {
      const element = document.querySelector('html');

      if (!element) return;

      if (isDarkTheme.value) {
        element.classList.add('p-dark');

        return;
      }

      element.classList.remove('p-dark');
    },
    {
      immediate: true,
    },
  );

  return { isDarkTheme: readonly(isDarkTheme), updateTheme };
}
