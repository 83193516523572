export default {
  root: ({ context }) => ({
    class: [
      `p-4 sm:p-4 md:font-bold text-[12px] sm:text-sm relative shrink-0`,
      {
        'after:block after:w-full after:h-[3px] after:absolute after:bg-primary after:bottom-0 after:-left-0 text-primary':
          context.active,
      },
    ],
  }),
};
