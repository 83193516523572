import revive_payload_client_4sVQNw7RlN from "/var/www/frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/www/frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/var/www/frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/var/www/frontend/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/www/frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/var/www/frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/var/www/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/www/frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tMGwffvjBc from "/var/www/frontend/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import primevue_plugin_egKpok8Auk from "/var/www/frontend/.nuxt/primevue-plugin.mjs";
import plugin_client_533RIki58s from "/var/www/frontend/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.mjs";
import stripe_6FZUdDHpIZ from "/var/www/frontend/plugins/stripe.ts";
import error_handler_kEP5FliEXj from "/var/www/frontend/plugins/error-handler.ts";
import sentry_3AyO8nEfhE from "/var/www/frontend/plugins/sentry.ts";
import vue_query_wrmMkNpEpe from "/var/www/frontend/plugins/vue-query.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tMGwffvjBc,
  primevue_plugin_egKpok8Auk,
  plugin_client_533RIki58s,
  stripe_6FZUdDHpIZ,
  error_handler_kEP5FliEXj,
  sentry_3AyO8nEfhE,
  vue_query_wrmMkNpEpe
]