export default {
  root: ({ props }) => ({
    class: [
      // Spacing and Shape
      'my-4 mx-0',
      'rounded-md',
      'border-solid border-0 border-l-[6px]',

      // Colors
      {
        'bg-message-info-background': props.severity == 'info',
        'bg-message-success-background': props.severity == 'success',
        'bg-message-warning-background': props.severity == 'warn',
        'bg-message-error-background': props.severity == 'error',
      },
      {
        'border-message-info-borderColor': props.severity == 'info',
        'border-message-success-borderColor': props.severity == 'success',
        'border-message-warning-borderColor': props.severity == 'warn',
        'border-message-error-borderColor': props.severity == 'error',
      },
      {
        'text-message-info-color': props.severity == 'info',
        'text-message-success-color': props.severity == 'success',
        'text-message-warning-color': props.severity == 'warn',
        'text-message-error-color': props.severity == 'error',
      },
    ],
  }),
  content: {
    class: [
      // Flexbox
      'flex items-center',

      // Spacing
      'p-3',
    ],
  },
  icon: {
    class: [
      // Sizing and Spacing
      'text-2xl',
      'text-lg leading-none mr-2 shrink-0',
    ],
  },
  text: {
    class: [
      // Font and text
      'text-sm ',
      'font-medium',
    ],
  },
  button: {
    class: [
      // Flexbox
      'flex items-center justify-center',

      // Size
      'w-8 h-8',

      // Spacing and Misc
      'ml-auto  relative',

      // Shape
      'rounded-full',

      // Colors
      'bg-transparent',

      // Transitions
      'transition duration-200 ease-in-out',

      // States
      'hover:bg-surface-0/50 dark:hover:bg-surface-0/10',

      // Misc
      'overflow-hidden',
    ],
  },
  transition: {
    enterFromClass: 'opacity-0',
    enterActiveClass: 'transition-opacity duration-300',
    leaveFromClass: 'max-h-40',
    leaveActiveClass: 'overflow-hidden transition-all duration-300 ease-in',
    leaveToClass: 'max-h-0 opacity-0 !m-0',
  },
};
