export default {
  root: {
    class: [
      //Shape
      'rounded-md',
      'border border-1 border-solid border-surface-border',
      //Color
      'text-surface-700 dark:text-surface-0',
      'bg-surface-section',

      //Spacing
      'space-y-5',
    ],
  },
  body: {
    class: 'p-4 text-bluegray-500 space-y-5 body',
  },
  title: {
    class: 'text-2xl font-bold mb-2 text-bluegray-900',
  },
  subtitle: {
    class: [
      //Font
      'text-md',
      'font-semibold',

      //Spacing

      //Color
      'text-bluegray-900 dark:text-surface-0/60',
    ],
  },
  content: {
    class: 'content',
  },
};
