export default {
  transition: {
    enterFromClass: 'max-h-0',
    enterActiveClass:
      'overflow-hidden transition-[max-height] duration-1000 ease-[cubic-bezier(0, 1, 0, 1)]',
    enterToClass: 'max-h-[2000px]',
    leaveFromClass: 'max-h-[2000px]',
    leaveActiveClass:
      'overflow-hidden transition-[max-height] duration-700 ease-[cubic-bezier(0, 1, 0, 1)]',
    leaveToClass: 'max-h-0',
  },
};
