<template>
  <section
    class="flex h-screen items-center justify-center bg-surface-section font-inter"
  >
    <div class="max-w-screen-xl px-4 py-8 lg:px-6 lg:py-16">
      <div class="mx-auto max-w-screen-sm space-y-6 text-center">
        <h1
          class="mb-4 text-3xl font-bold tracking-tight text-bluegray-900 md:text-4xl"
        >
          Ta strona już nie istnieje
        </h1>

        <p class="mb-4 text-lg font-light text-global-textColor">
          Przejdź do nowej wersji portalu kursanta i z pomocą wyszukiwarki
          znajdź interesujące Cię lekcje.
        </p>

        <slot></slot>
      </div>
    </div>
  </section>
</template>
