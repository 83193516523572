import { useQuery } from '@tanstack/vue-query';
import { userService } from '~/utils/api/userService';
import * as Sentry from '@sentry/vue';

export function useUser() {
  const { token } = useToken();
  const { data } = useQuery({
    queryKey: ['user'],
    queryFn: userService.getAccount,
    enabled: !!token.value,
  });

  watch(
    data,
    () => {
      if (data.value) {
        Sentry.setUser({
          email: data.value.email,
        });
      }
    },
    {
      immediate: true,
    },
  );

  const firstName = computed(() => {
    const name = data.value?.name;

    if (!name) {
      return '';
    }

    const [firstName] = name.split(' ') || '';

    return firstName;
  });

  const lastName = computed(() => {
    const name = data.value?.name;

    if (!name) {
      return '';
    }

    const [, lastName] = name.split(' ') || [];

    return lastName;
  });

  const isFirstLogin = computed(
    () => data.value?.created_at === data.value?.updated_at,
  );

  return { data, firstName, lastName, isFirstLogin };
}
