import { fetcher } from '../fetcher';

const USER_ENDPOINT = '/user';

export const userService = {
  getCertificates: async () => {
    return await fetcher(`${USER_ENDPOINT}/certificates`, {
      method: 'GET',
      schema: certificatesSchema,
    });
  },
  changePassword: async (payload: ChangePasswordProps) => {
    return await fetcher(`${USER_ENDPOINT}/change-password`, {
      method: 'POST',
      body: payload,
    });
  },
  getAccount: async () => {
    return await fetcher(`${USER_ENDPOINT}/account`, {
      method: 'GET',
      schema: userAccountSchema,
    });
  },
  updateAccount: async (payload: UserData) => {
    return await fetcher(`${USER_ENDPOINT}/account`, {
      method: 'POST',
      body: payload,
    });
  },
  deleteAccount: async () => {
    return await fetcher(`${USER_ENDPOINT}/account`, {
      method: 'DELETE',
    });
  },
  toggleTheme: async () => {
    return await fetcher(`${USER_ENDPOINT}/theme`, {
      method: 'POST',
    });
  },
  setOnboardingViewed: async (onboardingType: 'episode' | 'schedule') => {
    return await fetcher(
      `${USER_ENDPOINT}/complete-${onboardingType}-onboarding`,
      {
        method: 'POST',
      },
    );
  },
};
