import validate from "/var/www/frontend/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/var/www/frontend/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/var/www/frontend/middleware/auth.ts"),
  guest: () => import("/var/www/frontend/middleware/guest.ts"),
  "validate-reset-token": () => import("/var/www/frontend/middleware/validate-reset-token.ts")
}