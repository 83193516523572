import { loadStripe } from '@stripe/stripe-js';

export default defineNuxtPlugin(async (nuxtApp) => {
  const config = useRuntimeConfig();
  const key = config.public.STRIPE_KEY;

  if (typeof key !== 'string') {
    throw new Error('Missing Stripe key');
  }

  const stripe = await loadStripe(key);

  nuxtApp.provide('stripe', stripe);
});
