import { useStorage } from '@vueuse/core';

export function useToken() {
  const token = useStorage('token', '');

  function setToken(value: string) {
    token.value = value;
  }

  return {
    setToken,
    token,
  };
}
