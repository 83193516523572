export default {
  root: ({ context, props }) => ({
    class: [
      // Position and Shadows
      'absolute',
      'shadow-md',
      'p-fadein',
      // Spacing
      {
        'py-0 px-1':
          context?.right ||
          context?.left ||
          (!context?.right &&
            !context?.left &&
            !context?.top &&
            !context?.bottom),
        'py-1 px-0': context?.top || context?.bottom,
      },
    ],
  }),

  text: {
    class: [
      'p-3',
      'bg-surface-600 dark:bg-surface-700',
      'text-white',
      'leading-none',
      'rounded-md',
      'whitespace-pre-line',
      'break-words',
    ],
  },
};
