import { default as indexquQey4EiptMeta } from "/var/www/frontend/pages/checkout/[courseSlug]/cancel/index.vue?macro=true";
import { default as indexppCJ5iF5FTMeta } from "/var/www/frontend/pages/checkout/[courseSlug]/index.vue?macro=true";
import { default as indexxzZMdEbJiwMeta } from "/var/www/frontend/pages/checkout/[courseSlug]/success/index.vue?macro=true";
import { default as indexw23lKdKyWCMeta } from "/var/www/frontend/pages/contact/index.vue?macro=true";
import { default as indexrI7lzcuC51Meta } from "/var/www/frontend/pages/courses/[courseSlug]/lessons/[lessonSlug]/index.vue?macro=true";
import { default as indexwQ9gM45OEoMeta } from "/var/www/frontend/pages/courses/[courseSlug]/lessons/[lessonSlug]/topics/[topicSlug]/index.vue?macro=true";
import { default as indexOlqwwMJWlsMeta } from "/var/www/frontend/pages/exams/[slug]/index.vue?macro=true";
import { default as indexV37mLHP055Meta } from "/var/www/frontend/pages/index.vue?macro=true";
import { default as indexWuFLaCp8eYMeta } from "/var/www/frontend/pages/login/index.vue?macro=true";
import { default as indextJc1OuPrYEMeta } from "/var/www/frontend/pages/materials/index.vue?macro=true";
import { default as indexUpwrS7cB0EMeta } from "/var/www/frontend/pages/membership/index.vue?macro=true";
import { default as indexFCm75suPGyMeta } from "/var/www/frontend/pages/my-account/index.vue?macro=true";
import { default as index1qsQa1wOjNMeta } from "/var/www/frontend/pages/register/index.vue?macro=true";
import { default as indexThDqEqMwCdMeta } from "/var/www/frontend/pages/regulations/[slug]/index.vue?macro=true";
import { default as index6NsbU9UpetMeta } from "/var/www/frontend/pages/remind-password/[token]/index.vue?macro=true";
import { default as indexjtfAOMgu5cMeta } from "/var/www/frontend/pages/remind-password/index.vue?macro=true";
import { default as indexTfze6qTB4mMeta } from "/var/www/frontend/pages/removed-account/index.vue?macro=true";
import { default as indexmn19RPG16fMeta } from "/var/www/frontend/pages/settings/index.vue?macro=true";
export default [
  {
    name: "checkout-courseSlug-cancel",
    path: "/checkout/:courseSlug()/cancel",
    meta: indexquQey4EiptMeta || {},
    component: () => import("/var/www/frontend/pages/checkout/[courseSlug]/cancel/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout-courseSlug",
    path: "/checkout/:courseSlug()",
    meta: indexppCJ5iF5FTMeta || {},
    component: () => import("/var/www/frontend/pages/checkout/[courseSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout-courseSlug-success",
    path: "/checkout/:courseSlug()/success",
    meta: indexxzZMdEbJiwMeta || {},
    component: () => import("/var/www/frontend/pages/checkout/[courseSlug]/success/index.vue").then(m => m.default || m)
  },
  {
    name: "contact",
    path: "/contact",
    meta: indexw23lKdKyWCMeta || {},
    component: () => import("/var/www/frontend/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: "courses-courseSlug-lessons-lessonSlug",
    path: "/courses/:courseSlug()/lessons/:lessonSlug()",
    meta: indexrI7lzcuC51Meta || {},
    component: () => import("/var/www/frontend/pages/courses/[courseSlug]/lessons/[lessonSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "courses-courseSlug-lessons-lessonSlug-topics-topicSlug",
    path: "/courses/:courseSlug()/lessons/:lessonSlug()/topics/:topicSlug()",
    meta: indexwQ9gM45OEoMeta || {},
    component: () => import("/var/www/frontend/pages/courses/[courseSlug]/lessons/[lessonSlug]/topics/[topicSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "exams-slug",
    path: "/exams/:slug()",
    meta: indexOlqwwMJWlsMeta || {},
    component: () => import("/var/www/frontend/pages/exams/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexV37mLHP055Meta || {},
    component: () => import("/var/www/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: indexWuFLaCp8eYMeta || {},
    component: () => import("/var/www/frontend/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "materials",
    path: "/materials",
    meta: indextJc1OuPrYEMeta || {},
    component: () => import("/var/www/frontend/pages/materials/index.vue").then(m => m.default || m)
  },
  {
    name: "membership",
    path: "/membership",
    meta: indexUpwrS7cB0EMeta || {},
    component: () => import("/var/www/frontend/pages/membership/index.vue").then(m => m.default || m)
  },
  {
    name: "my-account",
    path: "/my-account",
    meta: indexFCm75suPGyMeta || {},
    component: () => import("/var/www/frontend/pages/my-account/index.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    meta: index1qsQa1wOjNMeta || {},
    component: () => import("/var/www/frontend/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: "regulations-slug",
    path: "/regulations/:slug()",
    meta: indexThDqEqMwCdMeta || {},
    component: () => import("/var/www/frontend/pages/regulations/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "remind-password-token",
    path: "/remind-password/:token()",
    meta: index6NsbU9UpetMeta || {},
    component: () => import("/var/www/frontend/pages/remind-password/[token]/index.vue").then(m => m.default || m)
  },
  {
    name: "remind-password",
    path: "/remind-password",
    meta: indexjtfAOMgu5cMeta || {},
    component: () => import("/var/www/frontend/pages/remind-password/index.vue").then(m => m.default || m)
  },
  {
    name: "removed-account",
    path: "/removed-account",
    meta: indexTfze6qTB4mMeta || {},
    component: () => import("/var/www/frontend/pages/removed-account/index.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "/settings",
    meta: indexmn19RPG16fMeta || {},
    component: () => import("/var/www/frontend/pages/settings/index.vue").then(m => m.default || m)
  }
]