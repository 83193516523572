import { ZodSchema } from 'zod';
import { ResponseError } from './responseError';
import type { HTTPMethod } from '~/utils/types';
import { fromZodError } from 'zod-validation-error';
import * as Sentry from '@sentry/vue';
import { useRuntimeConfig, useRouter } from '#app';

type FetcherConfig<Schema extends ZodSchema<any, any> | null> = {
  readonly method: HTTPMethod;
  readonly schema?: Schema;
  readonly body?: object;
  readonly config?: RequestInit;
  readonly customApiUrl?: string;
};

export async function fetcher<Schema extends ZodSchema<any, any> | null>(
  path: string,
  { method, body, config, schema, customApiUrl }: FetcherConfig<Schema>,
): Promise<Schema extends ZodSchema<infer T, any> ? T : null> {
  try {
    const { API_URL, BASIC_AUTH } = useRuntimeConfig().public;
    const token = localStorage.getItem('token');

    if (!API_URL) {
      throw new ResponseError('API_URL environment variable is not set', 111);
    }

    const response = await fetch(`${customApiUrl || API_URL}${path}`, {
      ...config,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Basic ${
          BASIC_AUTH ?? 'em1pZHVzZXI6c3VwZXJ1bmtub3du'
        }, ${token ? `Bearer ${token}` : ''}`,
        'Allow-Access-Control-Origin': '*',
        Accept: 'application/json',
        ...config?.headers,
      },
      credentials: 'include',
      method,
      ...(body && { body: JSON.stringify(body) }),
      mode: 'cors',
    });

    const data = await response.json();

    if (response.ok) {
      if (!schema) {
        return data.data;
      }

      const parsedData = schema.safeParse(data.data);

      if (!parsedData.success) {
        console.log(data.data);
        throw new ResponseError(fromZodError(parsedData.error).toString());
      }

      return parsedData.data;
    }

    if (response.status === 422) {
      if (
        typeof data.errors === 'object' &&
        data.errors !== null &&
        Array.isArray(Object.values(data.errors)[0])
      ) {
        const errors: Record<string, string[]> = data.errors;
        throw new ResponseError(errors[Object.keys(errors)[0]][0], 422);
      }
    }

    throw new ResponseError(data.message, response.status);
  } catch (err) {
    const router = useRouter();
    console.log(err);
    console.log(path);

    if (err instanceof ResponseError) {
      if (err.status === 401) {
        localStorage.removeItem('token');
        router.push('/login');

        throw err;
      } else if (err.status === 403) {
        showError({
          message: 'Nie masz dostępu do tej strony.',
        });

        throw err;
      } else if (err.status === 422) {
        throw err;
      } else if (method !== 'GET') {
        Sentry.captureException(err);

        throw err;
      } else if (err.status === 404) {
        Sentry.captureException(err);

        throw err;
      }
    }

    if (
      typeof err === 'object' &&
      err !== null &&
      'message' in err &&
      err?.message === 'Failed to fetch'
    ) {
      localStorage.removeItem('token');

      Sentry.captureException(err);

      showError({
        message:
          'Wystąpił błąd podczas pobierania danych. Zostałeś wylogowany. Spróbuj ponownie później.',
      });

      throw err;
    }

    Sentry.captureException(err);

    showError({
      message:
        'Coś poszło nie tak przy pobieraniu danych. Spróbuj ponownie później.',
    });

    throw err;
  }
}
